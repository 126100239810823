import bindAll from 'lodash.bindall';
import React from 'react';
import PropTypes from 'prop-types';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {connect} from 'react-redux';
import log from '../lib/log';
import sharedMessages from './shared-messages';
import Cookies from 'js-cookie';
import swal from '@sweetalert/with-react';

import {API} from '../services/api-service';
import * as Sentry from '@sentry/react';
import axios from 'axios';

import {
    LoadingStates,
    getIsLoadingUpload,
    getIsShowingWithoutId,
    onLoadedProject,
    requestProjectUpload
} from '../reducers/project-state';
import {setProjectTitle} from '../reducers/project-title';
import {
    openLoadingProject,
    closeLoadingProject
} from '../reducers/modals';
import {
    closeFileMenu
} from '../reducers/menus';

const JSZip = require('jszip');

const messages = defineMessages({
    loadError: {
        id: 'gui.projectLoader.loadError',
        defaultMessage: 'The project file that was selected failed to load.',
        description: 'An error that displays when a local project file fails to load.'
    }
});

/**
 * Higher Order Component to provide behavior for loading local project files into editor.
 * @param {React.Component} WrappedComponent the component to add project file loading functionality to
 * @returns {React.Component} WrappedComponent with project file loading functionality added
 *
 * <SBFileUploaderHOC>
 *     <WrappedComponent />
 * </SBFileUploaderHOC>
 */

const SBFileUploaderHOC = function (WrappedComponent) {
    class SBFileUploaderComponent extends React.Component {
        constructor (props) {
            super(props);
            bindAll(this, [
                'createFileObjects',
                'getProjectTitleFromFilename',
                'handleFinishedLoadingUpload',
                'handleStartSelectingFileUpload',
                'handleChange',
                'onload',
                'removeFileObjects',
                'trimFileName'
            ]);
            this.state = {
                exerciseResponse: '',
                exerciseId: ''
            };
        }
        componentDidMount () {
            let url = window.location.href
            var newUrl = new URL(url);
            let exerciseId = newUrl.searchParams.get("exerciseId");
            let submissionId = newUrl.searchParams.get("submissionId");
            this.setState({
                exerciseId: exerciseId,
                submissionId: submissionId
            });
            // Set location cookie to sandbox if it is not present.
            if (!Cookies.get('location')) {
                Cookies.set('location', 'sandbox', {
                    path: '/',
                    domain: '.codeyoung.com'
                });
            }
            // Check if FileId and type exists in Localstorage to load file
            let sandboxUser = JSON.parse(localStorage.getItem("sandbox_user"));
            if ((Cookies.get('fileId') !== 'undefined' && Cookies.get('type')!== 'undefined') || exerciseId !== null) {
                const lsObj = sandboxUser;
                const cookieVal = Cookies.get('location');
                if (cookieVal === 'community') {
                    this.handleCommunityProject();
                } else if (cookieVal === 'exercise') {
                    this.createFileObjects(exerciseId, lsObj.type, submissionId);
                } else {
                    this.createFileObjects(lsObj.fileId, lsObj.type, submissionId);
                }
            }
        }
        componentDidUpdate (prevProps) {
            if (this.props.isLoadingUpload && !prevProps.isLoadingUpload) {
                this.handleFinishedLoadingUpload(); // cue step 5 below
            }
        }
        componentWillUnmount () {
            this.removeFileObjects();
        }

        // step 1: this is where the upload process begins
        handleStartSelectingFileUpload () {
            this.createFileObjects(); // go to step 2
        }
        // step 2: create a FileReader and an <input> element, and issue a
        // pseudo-click to it. That will open the file chooser dialog.
        createFileObjects (fileId, type, submissionId) {
            const cookieVal = Cookies.get('location');
            // redo step 7, in case it got skipped last time and its objects are
            // still in memory
            this.removeFileObjects();
            // create fileReader
            this.fileReader = new FileReader();
            this.fileReader.onload = this.onload;
            if (cookieVal === 'exercise') {
                API.get(`/exercises`, {
                    catalogId: Cookies.get('catalogueId'),
                    exerciseId: fileId,
                    submissionId: submissionId
                })
                    .then(res => {
                        setTimeout(() => {
                            this.handleChange(res.data.responseData.code, `${this.state.exerciseId}.sb3`);
                        }, 3000);
                        // this.props.onSetExerciseContent(res.data.responseData);
                        this.setState({
                            exerciseResponse: res.data.responseData
                        });
                    })
                    .catch(err => {
                        console.error('error while fetching exersie data', err);
                    });
            } else {
                API.post('/readScratchFile', {
                    fileId: fileId,
                    type: type
                }).then(res => {
                    const date = Date.now();
                    setTimeout(() => {
                        this.handleChange(`${res.data.s3FileUrl}?${date}`, res.data.fileName);
                    }, 3000);
                    localStorage.removeItem('nextFileId');
                    if (res.data.nextFileId) {
                        localStorage.setItem('nextFileId', res.data.nextFileId);
                    }
                })
                    .catch(error => {
                        let title;
                        if (error.response.status === 401) {
                            title = 'Login expired. Please go back to Workbench.';
                        } else {
                            title = 'Error while Reading File';
                            const event = {
                                message: `Error while Reading Scratch File`,
                                extra: {
                                    fileId: Cookies.get('fileId')
                                }
                            };
                            Sentry.captureEvent(event);
                        }
                        swal(
                            {
                                title: title,
                                icon: 'error'
                            }
                        );
                        console.error('Error while Reading FIle', error);
                    });
            }


            // axios.post('http://localhost:9021/readScratchFile',{
            //     fileId: '213GSt32aXAlGs552N',
            //     type: 'personalCollection'
            // }, {
            //     headers: {
            //       Accept: 'application/zip',
            //       Authorization: JSON.parse(localStorage.getItem("sandbox_user")).ACCESS_TOKEN,
            //       ["refresh-token"]: JSON.parse(localStorage.getItem("sandbox_user")).REFRESH_TOKEN,
            //     },
            //     responseType: 'arraybuffer',
            // }).then(res => {
            //     this.handleChange(res.data)
            // });

            // create <input> element and add it to DOM
            // this.inputElement = document.createElement('input');
            // this.inputElement.accept = '.sb,.sb2,.sb3';
            // this.inputElement.style = 'display: none;';
            // this.inputElement.type = 'file';
            // this.inputElement.onchange = this.handleChange; // connects to step 3
            // document.body.appendChild(this.inputElement);
            // // simulate a click to open file chooser dialog
            // this.inputElement.click();
        }

        handleCommunityProject = () => {
            this.removeFileObjects();
            // create fileReader
            this.fileReader = new FileReader();
            this.fileReader.onload = this.onload;
            const headers = {
                'Content-Type': 'application/json', // Set the Content-Type header
                'Cache-Control': 'no-cache', // Set the Cache-Control header
              };

            axios.get(`https://sandbox-api.dev.codeyoung.com/share/readContent?fileId=${Cookies.get('fileId')}`, {headers}
            ).then(res => {
                setTimeout(() => {
                    this.handleChange(res.data.s3FileUrl, res.data.fileName);
                }, 3000);
                localStorage.removeItem('nextFileId');
                if (res.data.nextFileId) {
                    localStorage.setItem('nextFileId', res.data.nextFileId);
                }
            })
                .catch(e => {
                    const event = {
                        message: `Error while Reading Scratch File`,
                        extra: {
                            fileId: Cookies.get('fileId')
                        }
                    };
                    Sentry.captureEvent(event);
                    console.error('Error while Reading FIle', e);
                });
        }

        // step 3: user has picked a file using the file chooser dialog.
        // We don't actually load the file here, we only decide whether to do so.
        handleChange (
            codeFile,
            fileName
            // e
        ) {
            const {
                intl,
                isShowingWithoutId,
                loadingState,
                projectChanged,
                userOwnsProject
            } = this.props;
            // const thisFileInput = e.target;
           
            // const blob = new Blob([codeFile]);
            // const file = new File([blob], 'scratch.sb3', {
            //     type: 'application/x.scratch.sb3'
            // });
            fetch(codeFile).then(response => {
                response.blob().then(data => {
                    const file = new File([data], fileName);
                    const thisFileInput = file;
                    if (thisFileInput) { // Don't attempt to load if no file was selected
                        this.fileToUpload = file;
                        // this.fileToUpload.name = fileName;
                        // this.fileToUpload = thisFileInput.files[0];
            
                        // If user owns the project, or user has changed the project,
                        // we must confirm with the user that they really intend to
                        // replace it. (If they don't own the project and haven't
                        // changed it, no need to confirm.)
                        let uploadAllowed = true;
                        if (userOwnsProject || (projectChanged && isShowingWithoutId)) {
                            uploadAllowed = confirm( // eslint-disable-line no-alert
                                intl.formatMessage(sharedMessages.replaceProjectWarning)
                            );
                        }

                        if (uploadAllowed) {
                            // cues step 4
                            this.props.requestProjectUpload(loadingState);
                        } else {
                            // skips ahead to step 7
                            this.removeFileObjects();
                        }
                        this.props.closeFileMenu();
                    }
                });
            });
        
        }
        // step 4 is below, in mapDispatchToProps

        // step 5: called from componentDidUpdate when project state shows
        // that project data has finished "uploading" into the browser
        handleFinishedLoadingUpload () {
            if (this.fileToUpload && this.fileReader) {
                // begin to read data from the file. When finished,
                // cues step 6 using the reader's onload callback
                this.fileReader.readAsArrayBuffer(this.fileToUpload);
            } else {
                this.props.cancelFileUpload(this.props.loadingState);
                // skip ahead to step 7
                this.removeFileObjects();
            }
        }
        trimFileName (name) {
            if (name) {
                if (name.includes('.')) {
                    const parts = name.split('.');
                    return parts[0];
                }
                return name;
            }
            return '';
        }

        // used in step 6 below
        getProjectTitleFromFilename (fileInputFilename) {
            if (!fileInputFilename) return '';
            // only parse title with valid scratch project extensions
            // (.sb, .sb2, and .sb3)
            if (Cookies.get('fileName')) {
                const filename = this.trimFileName(Cookies.get('fileName'));
                return filename;
                // return Cookies.get('fileName')
            }
            const matches = fileInputFilename.match(/^(.*)\.sb[23]?$/);
            if (!matches) return '';
            return matches[1].substring(0, 100); // truncate project title to max 100 chars
        }
        // step 6: attached as a handler on our FileReader object; called when
        // file upload raw data is available in the reader
        onload () {
            if (this.fileReader) {
                this.props.onLoadingStarted();
                const filename = this.fileToUpload && this.fileToUpload.name;
                let loadingSuccess = false;
                this.props.vm.loadProject(this.fileReader.result)
                    .then(() => {
                        if (filename) {
                            const uploadedProjectTitle = this.getProjectTitleFromFilename(filename);
                            this.props.onSetProjectTitle(uploadedProjectTitle);
                        }
                        loadingSuccess = true;
                    })
                    .catch(error => {
                        console.log('error', error);
                        alert(this.props.intl.formatMessage(messages.loadError)); // eslint-disable-line no-alert
                    })
                    .then(() => {
                        this.props.onLoadingFinished(this.props.loadingState, loadingSuccess);
                        // go back to step 7: whether project loading succeeded
                        // or failed, reset file objects
                        this.removeFileObjects();
                    });
            }
        }
        // step 7: remove the <input> element from the DOM and clear reader and
        // fileToUpload reference, so those objects can be garbage collected
        removeFileObjects () {
            if (this.inputElement) {
                this.inputElement.value = null;
                document.body.removeChild(this.inputElement);
            }
            this.inputElement = null;
            this.fileReader = null;
            this.fileToUpload = null;
        }
        render () {
            const {
                /* eslint-disable no-unused-vars */
                cancelFileUpload,
                closeFileMenu: closeFileMenuProp,
                isLoadingUpload,
                isShowingWithoutId,
                loadingState,
                onLoadingFinished,
                onLoadingStarted,
                onSetProjectTitle,
                onSetExerciseContent,
                projectChanged,
                requestProjectUpload: requestProjectUploadProp,
                userOwnsProject,
                /* eslint-enable no-unused-vars */
                ...componentProps
            } = this.props;
            return (
                <React.Fragment>
                    <WrappedComponent
                        onStartSelectingFileUpload={this.handleStartSelectingFileUpload}
                        exerciseResponse={this.state.exerciseResponse}
                        {...componentProps}
                    />
                </React.Fragment>
            );
        }
    }

    SBFileUploaderComponent.propTypes = {
        canSave: PropTypes.bool,
        cancelFileUpload: PropTypes.func,
        closeFileMenu: PropTypes.func,
        intl: intlShape.isRequired,
        isLoadingUpload: PropTypes.bool,
        isShowingWithoutId: PropTypes.bool,
        loadingState: PropTypes.oneOf(LoadingStates),
        onLoadingFinished: PropTypes.func,
        onLoadingStarted: PropTypes.func,
        onSetProjectTitle: PropTypes.func,
        onSetExerciseContent: PropTypes.func,
        projectChanged: PropTypes.bool,
        requestProjectUpload: PropTypes.func,
        userOwnsProject: PropTypes.bool,
        vm: PropTypes.shape({
            loadProject: PropTypes.func
        })
    };
    const mapStateToProps = (state, ownProps) => {
        const loadingState = state.scratchGui.projectState.loadingState;
        const user = state.session && state.session.session && state.session.session.user;
        return {
            isLoadingUpload: getIsLoadingUpload(loadingState),
            isShowingWithoutId: getIsShowingWithoutId(loadingState),
            loadingState: loadingState,
            projectChanged: state.scratchGui.projectChanged,
            userOwnsProject: ownProps.authorUsername && user &&
                (ownProps.authorUsername === user.username),
            vm: state.scratchGui.vm,
            exercise: state.scratchGui.exercise
        };
    };
    const mapDispatchToProps = (dispatch, ownProps) => ({
        cancelFileUpload: loadingState => dispatch(onLoadedProject(loadingState, false, false)),
        closeFileMenu: () => dispatch(closeFileMenu()),
        // transition project state from loading to regular, and close
        // loading screen and file menu
        onLoadingFinished: (loadingState, success) => {
            dispatch(onLoadedProject(loadingState, ownProps.canSave, success));
            dispatch(closeLoadingProject());
            dispatch(closeFileMenu());
        },
        // show project loading screen
        onLoadingStarted: () => dispatch(openLoadingProject()),
        onSetProjectTitle: title => dispatch(setProjectTitle(title)),
        // step 4: transition the project state so we're ready to handle the new
        // project data. When this is done, the project state transition will be
        // noticed by componentDidUpdate()
        requestProjectUpload: loadingState => dispatch(requestProjectUpload(loadingState))
    });
    // Allow incoming props to override redux-provided props. Used to mock in tests.
    const mergeProps = (stateProps, dispatchProps, ownProps) => Object.assign(
        {}, stateProps, dispatchProps, ownProps
    );
    return injectIntl(connect(
        mapStateToProps,
        mapDispatchToProps,
        mergeProps
    )(SBFileUploaderComponent));
};

export {
    SBFileUploaderHOC as default
};
